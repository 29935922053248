<template>
  <div class="wait-commend bsbb">
    <div class="all-commend df aic jcsb font-size-24 p15 bsbb mb20">
      <div class="df aic">
        <img src="../../assets/images/xg/commend.png" class="mr20" />
        <p class="fw7">我的评价</p>
      </div>
      <van-icon name="arrow" @click="$router.push('/user1')" />
    </div>
    <div class="order-list df fdc">
      <div class="item mb20 p15 bsbb">
        <div
          class="df aic jcsb font-size-24 fw7"
          style="border-bottom: 1px solid #f2f2f2; padding-bottom: 8px"
        >
          <p>菜大全鲜果蔬菜成都店</p>
          <p>退款成功</p>
        </div>
        <div class="df aic jcsb text p15 bsbb">
          <div class="df aic font-size-20">
            <img src="../../assets/images/xg/list5.png" class="mr20" />
            <p style="color: #999" class="df fdc aic jcc">
              <span class="font-size-20">香蕉500gx1</span>
              <span class="font-size-14 mt10">共1件</span>
            </p>
          </div>
          <div class="df fdc re">
            <p class="font-size-16" style="color: #666">
              合计<span class="font-size-24 fw7" style="color: #000"
                >¥27.28</span
              >
            </p>
            <div>
              <van-button
                size="large"
                type="default"
                class="mr20 line"
                @click="$router.push('/afterSale')"
                >退款进度</van-button
              >
              <van-button size="large" type="success">再来一单</van-button>
            </div>
          </div>
        </div>
      </div>
      <div class="item mb20 p15 bsbb">
        <div
          class="df aic jcsb font-size-24 fw7"
          style="border-bottom: 1px solid #f2f2f2; padding-bottom: 8px"
        >
          <p>菜大全鲜果蔬菜成都店</p>
          <p>退款成功</p>
        </div>
        <div class="df aic jcsb text p15 bsbb">
          <div class="df aic font-size-20">
            <img src="../../assets/images/xg/list5.png" class="mr20" />
            <p style="color: #999" class="df fdc aic jcc">
              <span class="font-size-20">香蕉500gx1</span>
              <span class="font-size-14 mt10">共1件</span>
            </p>
          </div>
          <div class="df fdc re">
            <p class="font-size-16" style="color: #666">
              合计<span class="font-size-24 fw7" style="color: #000"
                >¥27.28</span
              >
            </p>
            <div>
              <van-button
                size="large"
                type="default"
                class="mr20 line"
                @click="$router.push('/afterSale')"
                >退款进度</van-button
              >
              <van-button size="large" type="success">再来一单</van-button>
            </div>
          </div>
        </div>
      </div>
      <div class="item mb20 p15 bsbb">
        <div
          class="df aic jcsb font-size-24 fw7"
          style="border-bottom: 1px solid #f2f2f2; padding-bottom: 8px"
        >
          <p>菜大全鲜果蔬菜成都店</p>
          <p>退款成功</p>
        </div>
        <div class="df aic jcsb text p15 bsbb">
          <div class="df aic font-size-20">
            <img src="../../assets/images/xg/list5.png" class="mr20" />
            <p style="color: #999" class="df fdc aic jcc">
              <span class="font-size-20">香蕉500gx1</span>
              <span class="font-size-14 mt10">共1件</span>
            </p>
          </div>
          <div class="df fdc re">
            <p class="font-size-16" style="color: #666">
              合计<span class="font-size-24 fw7" style="color: #000"
                >¥27.28</span
              >
            </p>
            <div>
              <van-button
                size="large"
                type="default"
                class="mr20 line"
                @click="$router.push('/afterSale')"
                >退款进度</van-button
              >
              <van-button size="large" type="success">再来一单</van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wait-commend {
  width: 100%;
  height: 100%;
  .all-commend {
    width: 690px;
    height: 86px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    img {
      width: 35.99px;
      height: 36px;
    }
  }
  .order-list {
    .item {
      width: 684px;
      height: 213px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);
      .text {
        img {
          width: 100px;
          height: 100px;
          border-radius: 8px;
        }
        .re {
          text-align: right;
          .line {
            border: 1px solid rgba(85, 187, 73, 1);
          }
        }
        .van-button {
          width: 129px;
          height: 57px;
          border-radius: 15px;
        }
      }
    }
  }
}
</style>